import Department from 'app/types/Department';
import Member from 'app/types/Member';
import { Role } from 'app/types/Role';
import { Accordion, AccordionItem, Typography } from 'cfa-react-components';
import { PointOfContactAccordionList } from './PointOfContactAccordionList';

export type PointOfContactAccordionProps = {
  currentDept: string;
  subDeptMap: Map<string, Department>;
  roleList: Role[];
  pointOfContactList: Member[];
};

export const PointOfContactAccordionMenu = ({
  currentDept,
  subDeptMap,
  roleList,
  pointOfContactList,
}: PointOfContactAccordionProps) => {
  return (
    <Accordion
      style={{
        paddingLeft: '50px',
        paddingRight: '50px',
      }}
    >
      <AccordionItem
        itemKey={subDeptMap.get(currentDept)?.name!}
        header={
          <Typography
            variant="overline1"
            color="primary"
            align="center"
            data-cy="pointsOfContact"
          >
            Subdepartment Points Of Contact
          </Typography>
        }
      >
        <PointOfContactAccordionList
          currentDept={currentDept}
          subDeptMap={subDeptMap}
          pointOfContactList={pointOfContactList}
          roleList={roleList}
        />
      </AccordionItem>
    </Accordion>
  );
};
export default PointOfContactAccordionMenu;
