import IdentityUser from 'app/types/IdentityUser';
import { Container, Icon } from 'cfa-react-components';
import { useState } from 'react';
// import RenderedItem from "./RenderedItem";
// import EmployeeInfoModal from "./EmployeeInfoModal";
import { Search } from '@cfa-icons/system';
import { useNavigate } from 'react-router-dom';

export type SearchBar2Props = {
  users1?: IdentityUser[];
};

export const SearchBar2 = ({ users1 }: SearchBar2Props) => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  return (
    <>
      <Container style={{ height: '46px', width: '100%' }}>
        <div className="search-container">
          <input
            className="search-bar"
            type="text"
            placeholder="Search..."
            value={query}
            data-cy="search-bar"
            onChange={e => setQuery(e.target.value)}
            onKeyDown={a => {
              if (a.key == 'Enter') {
                navigate(`/search?q=${query}`);
              }
            }}
          ></input>
          <Icon
            icon={Search}
            size="md"
            color="#5B6770"
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            onClick={() => {
              navigate(`/search?q=${query}`);
              setQuery(query);
            }}
          />
        </div>
      </Container>
    </>
  );
};

export default SearchBar2;
