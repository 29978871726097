import {
  COUNCILS_ADMIN,
  DTT_LOGIN,
  DTT_PODS,
  GUILDS_ADMIN,
  PODS_ADMIN,
  SG_ADMIN,
} from 'app/constants/AppPermissions';
import { hasPermission } from './hasPermission';

/* Functions per feature that allow certain access */
/* Never need to specify admin access */

export const allowCreateEditPod = () => {
  return hasPermission(DTT_PODS, [
    DTT_LOGIN,
    PODS_ADMIN,
    GUILDS_ADMIN,
    SG_ADMIN,
    COUNCILS_ADMIN,
  ]);
};

export const allowEditPodMember = () => {
  return hasPermission(DTT_PODS, [
    DTT_LOGIN,
    PODS_ADMIN,
    GUILDS_ADMIN,
    SG_ADMIN,
    COUNCILS_ADMIN,
  ]);
};

export const allowEditPodName = () => {
  return hasPermission(DTT_PODS, [PODS_ADMIN]);
};

export const allowDeletePod = () => {
  return hasPermission(DTT_PODS, [PODS_ADMIN]);
};

export const allowEditGuild = () => {
  return hasPermission(DTT_PODS, [GUILDS_ADMIN]);
};

export const allowEditSG = () => {
  return hasPermission(DTT_PODS, [SG_ADMIN]);
};

export const allowEditLT = () => {
  return hasPermission(DTT_PODS, [COUNCILS_ADMIN]);
};

export const adminAccess = () => {
  return hasPermission(DTT_PODS, []);
};

export const allowAddPointOfContact = () => {
  return hasPermission(DTT_PODS, [PODS_ADMIN]);
};
