import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { PointsOfContactAdapter } from './slice';

export const selectPointOfContactReducerState = (store: RootState) =>
  store.pointsOfContact;

const pointOfContactSelectors = PointsOfContactAdapter.getSelectors(
  selectPointOfContactReducerState,
);

export const selectPointsOfContact = (store: RootState) =>
  pointOfContactSelectors.selectAll(store);

export const selectPointsOfContactByDeptId1 =
  (deptId: string) => (store: RootState) =>
    pointOfContactSelectors
      .selectAll(store)
      .filter(a => a.groupId.localeCompare(deptId) === 0);

export const selectPointsOfContactByDeptId = (deptId: string) =>
  createSelector(
    [selectPointsOfContactByDeptId1(deptId)],
    pointsOfContact => pointsOfContact,
  );

export const selectPointOfContactById =
  (employeeId: string) => (store: RootState) =>
    pointOfContactSelectors.selectById(store, employeeId);
