import IdentityUser from 'app/types/IdentityUser';
import AppConstants from 'app/utils/AppConstants';
import { Avatar } from 'cfa-react-components';
import { useEffect, useState } from 'react';

export type EmployeePictureProps = {
  user: IdentityUser;
  imgWidth: string;
  imgHeight: string;
};

export const EmployeePicture = ({
  user,
  imgWidth,
  imgHeight,
}: EmployeePictureProps) => {
  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(() => {
    if (user != undefined && user.AUDIENCE_TAG === 'STAFF_AUDIENCE') {
      setImageUrl(`${AppConstants.STAFF_PHOTO_URL}/${user.employeeNumber}.jpg`);
    }
  }, [user]);

  return (
    <Avatar
      color="secondary"
      imageSrc={imageUrl}
      style={{
        width: imgWidth,
        height: imgHeight,
      }}
      data-cy="profile-picture"
    />
  );
};

export default EmployeePicture;
