import { useAppSelector } from 'app/store';
import { selectUsers } from 'app/store/users/selectors';
import IdentityUser from 'app/types/IdentityUser';
import Member from 'app/types/Member';
import Role from 'app/types/Role';
import { Col, Divider, Row } from 'cfa-react-components';
import { PropsWithChildren, useContext, useEffect } from 'react';
import InputRow from '../InputRow';
import { MembersContext } from './MembersContext';
import { SaveCloseButtonWrapper } from './SaveCloseButtonWrapper';

// Shared Component for editing the members of a page on a group Profile page
// This is the members section where you can add or remove members from a group
// Your choice of buttons should be passed as children at the bottom (Save and Close, etc)
type EditMembersProps = {
  members?: Member[];
  rolesList: Role[];
} & PropsWithChildren<{}>;

export const EditMembers = ({
  members,
  rolesList,
  children,
}: EditMembersProps) => {
  const context = useContext(MembersContext);
  const { inputRowList, setInputRowList } = context;
  const users = useAppSelector(selectUsers);

  useEffect(() => {
    const fetchExistingMembers = () => {
      if (members) {
        setInputRowList(
          members.map((member, index) => ({
            index: index,
            role: rolesList.find(role => role.id === member.roleId) ?? null,
            user: users.find(user => user.GUID === member.userId) ?? null,
            deleted: false,
          })),
        );
      }
    };
    fetchExistingMembers();
  }, [users]);

  const handleChangeInputRow = (
    index: number,
    tempRole: Role | null,
    tempUser: IdentityUser | null,
    from: string,
  ) => {
    const tempInputRows = [...inputRowList];
    if (from == 'USER') {
      tempInputRows[index] = {
        index: tempInputRows[index].index,
        role: tempInputRows[index].role,
        user: tempUser,
        deleted: false,
      };
    }
    if (from == 'ROLE') {
      tempInputRows[index] = {
        index: tempInputRows[index].index,
        role: tempRole,
        user: tempInputRows[index].user,
        deleted: false,
      };
    }
    setInputRowList(tempInputRows);
  };
  const handleDeleteInputRow = (
    indexToDelete: number,
    user: IdentityUser | null,
  ) => {
    // find if the row we are deleting has already been saved to the pod
    // const podMember = members?.find(member => member.userId === user?.GUID);
    // mark the row as deleted to trigger UI change
    const tempInputRows = [...inputRowList];
    tempInputRows[indexToDelete] = {
      index: tempInputRows[indexToDelete].index,
      role: tempInputRows[indexToDelete].role,
      user: tempInputRows[indexToDelete].user,
      deleted: true,
    };
    setInputRowList(tempInputRows);
    // if (!podMember) {
    //   // TODO: this is what was breaking things
    //   // if the row was never saved to the pod, remove the row from the list in order to preserve the correct state of the rows
    //   tempInputRows.splice(indexToDelete, 1);
    //   setInputRowList(tempInputRows);
    // }
  };

  return (
    <>
      <div
        style={{
          width: '800px',
          alignSelf: 'center',
        }}
      >
        <Divider
          variant="fullLength"
          orientation="horizontal"
          style={{ marginBottom: '10px', marginTop: '10px' }}
        ></Divider>
        <Col sm={12}>
          <Row
            id="pod-members"
            data-cy="pod-members"
            style={{
              marginBottom: '1rem',
              marginTop: '15px',
              justifyContent: 'center',
              maxHeight: '500px',
              overflowY: 'scroll',
            }}
            key="inputRowList"
          >
            {inputRowList?.map(item => {
              if (item.deleted != true) {
                return (
                  <InputRow
                    key={item.index}
                    tempUser={item.user}
                    tempRole={item.role}
                    onDelete={() =>
                      handleDeleteInputRow(item.index, item?.user)
                    }
                    rolesList={rolesList}
                    index={item.index}
                    handleInputChange={handleChangeInputRow}
                    originalLength={members?.length}
                  />
                );
              }
              return <></>;
            })}
          </Row>
        </Col>
        <Divider
          variant="fullLength"
          orientation="horizontal"
          style={{ marginBottom: '10px' }}
        ></Divider>
        <SaveCloseButtonWrapper>{children}</SaveCloseButtonWrapper>
      </div>
    </>
  );
};
