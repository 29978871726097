import { ChevronDown } from '@cfa-icons/system';
import Department from 'app/types/Department';
import { LeadershipTeam } from 'app/types/Leadership';
import Member from 'app/types/Member';
import Role from 'app/types/Role';
import { Card, CardContent, Icon, Typography } from 'cfa-react-components';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../App.scss';
import RoleGroups from '../CardRoleGroups/RoleGroups';
import './LeadershipCard.scss';

export type LeadershipCardProps = {
  rolesList: Role[];
  deptList: Department[];
  leadership: LeadershipTeam;
  leadershipMembers: Member[];
  onProfile?: boolean;
};

export const LeadershipCard = ({
  rolesList,
  deptList,
  leadershipMembers,
  leadership,
  onProfile,
}: LeadershipCardProps) => {
  rolesList?.sort((role1, role2) => role1.name.localeCompare(role2.name));
  let dept = deptList.find(dept => dept.id === leadership.deptId) ?? undefined;

  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(true);

  const handleClick = (leadershipId: string) => {
    navigate(`/leadership-teams/${leadershipId}`);
  };

  const handleCollapse = (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {
    event?.stopPropagation();
    setCollapse(!collapse);
  };

  return (
    <Card
      className="leadership-card"
      elevation={8}
      variant="default"
      onClick={e => handleCollapse(e)}
      data-cy="expand-lt"
    >
      <React.Fragment key=".0">
        <CardContent>
          <div className="name-arrow">
            <Typography
              onClick={() => handleClick(leadership.id)}
              className="leadership-name"
              color="secondary"
              variant="h4"
              align="left"
            >
              {leadership.name}
            </Typography>
            {onProfile != undefined ? (
              <Icon
                style={{
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  paddingLeft: '4px',
                }}
                icon={ChevronDown}
              ></Icon>
            ) : (
              <></>
            )}
          </div>
          <Typography variant="body2">{dept?.name}</Typography>
          <div
            className={
              onProfile && collapse ? 'card-collapsed' : 'card-uncollapsed'
            }
          >
            <div style={{ paddingBottom: '6px' }}></div>
            <RoleGroups<LeadershipTeam>
              key={leadership.name}
              rolesList={rolesList}
              group={leadership}
              members={leadershipMembers}
            />
          </div>
        </CardContent>
      </React.Fragment>
    </Card>
  );
};

export default LeadershipCard;
