import pictureApi from 'app/services/PicturesApi';
import {
  EmployeeImageData,
  ImageData,
  ImageResponse,
} from 'app/types/ImageTypes';
import AppConstants from './AppConstants';

export function download(blob: string, filename: string) {
  const fakeLink = window.document.createElement('a');
  fakeLink.download = filename;
  fakeLink.href = blob;
  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);
  fakeLink.remove();
}

export const createDownloads = async (
  userImageData: EmployeeImageData[],
  staffOnly: boolean,
  teamName: string,
) => {
  const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };

  try {
    const items = await Promise.all(
      userImageData
        .filter(member => {
          return !staffOnly || member.userType == 'Staff';
        })
        .map(async imageData => {
          let photo;
          if (imageData.employeeNumber == 'NO_VALUE') {
            photo = await loadImage(
              `data:image/jpg;base64, ${AppConstants.DEFAULT_PHOTO_STRING}`,
            );
          } else {
            try {
              const resp = await pictureApi.get_image_by_id(
                imageData.employeeNumber,
              );
              const data: ImageResponse = await resp.json();
              photo = await loadImage(`data:image/jpg;base64,${data.img_data}`);
            } catch (error) {
              //NEED TO CREATE ERROR TOAST
              photo = await loadImage(
                `data:image/jpg;base64, ${AppConstants.DEFAULT_PHOTO_STRING}`,
              );
            }
          }
          const temp: ImageData = {
            image: photo,
            name: imageData.name,
            role: imageData.role?.name ?? 'Member',
            userType: imageData.userType,
          };
          return temp;
        }),
    );

    //Positioning of photos
    let canvas = document.createElement('canvas');
    canvas.width = 1500;
    canvas.height = 640;
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('Error creating canvas');
    }

    let counter = 0;
    let remainingItems = items.filter(
      item => item !== undefined && item.image != undefined,
    ).length;
    let secondRowLength: number = 0;
    let firstRowLength: number;
    if (remainingItems > 10) {
      firstRowLength = 5;
      secondRowLength = 5;
    } else if (remainingItems > 5) {
      secondRowLength = remainingItems / 2;
      firstRowLength = remainingItems - secondRowLength;
    } else {
      firstRowLength = remainingItems;
    }

    items
      .filter(item => item !== undefined && item.image != undefined)
      .forEach(item => {
        let yIndex = Math.floor(counter / firstRowLength) * 320;
        let xIndex = (counter % firstRowLength) * 300;
        const newWidth = item!.image.width * 2;
        const newHeight = item!.image.height * 2;
        ctx.drawImage(item!.image, xIndex, yIndex, newWidth, newHeight);
        ctx.font = '22px apercu';
        ctx.textAlign = 'center';
        ctx.fillStyle = 'rgb(93, 103, 112)';
        ctx.fillText(item!.name, xIndex + newWidth / 2, yIndex + 250);
        ctx.fillText(item!.role!, xIndex + newWidth / 2, yIndex + 278);

        if (counter == 9) {
          const finalImage = canvas.toDataURL('image/jpg', 1.0);
          download(finalImage, `${teamName}.jpg`);
          counter = -1;
          remainingItems -= 10;
          secondRowLength = remainingItems > 5 ? remainingItems / 2 : 0;
          firstRowLength = remainingItems - secondRowLength;
          ctx.clearRect(0, 0, 1500, 640);
        }
        counter++;
      });
    if (counter > 0) {
      const finalImage = canvas.toDataURL('image/jpg', 1.0);
      download(finalImage, `${teamName}.jpg`);
    }
  } catch (error) {
    //Need to create error notification
    console.error('Error processing images:', error);
  }
};
