import memberApi from 'app/services/MembersApi';
import Department from 'app/types/Department';
import IdentityUser from 'app/types/IdentityUser';
import Member from 'app/types/Member';
import MemberStatus from 'app/types/MemberStatus';
import MemberType from 'app/types/MemberType';
import Role from 'app/types/Role';
import { AppAction } from '..';
import {
  addPointOfContact,
  deletePointOfContact,
  setPointsOfContactError,
  setPointsOfContactRequest,
  setPointsOfContactSuccess,
  updatePointOfContact as updatePointOfContactSlice,
} from './slice';

export const loadPointsOfContact = (): AppAction<Promise<void>> => dispatch => {
  dispatch(setPointsOfContactRequest());
  return memberApi.members
    .getMembersByType(MemberType.POINT_OF_CONTACT)
    .then(resp => {
      dispatch(setPointsOfContactSuccess(resp));
    })
    .catch(error => {
      dispatch(setPointsOfContactError(error));
    });
};

export const createPointOfContact =
  (
    user: IdentityUser,
    dept: Department,
    role: Role,
  ): AppAction<Promise<void>> =>
  dispatch => {
    return memberApi.members
      .createMember(
        {
          userId: user.GUID,
          groupId: dept.id,
          type: MemberType.POINT_OF_CONTACT,
          name: user.displayName as string,
          isContingent: user.AUDIENCE_TAG === 'CONTRACTOR_AUDIENCE',
          status: MemberStatus.ACTIVE,
        },
        role?.id,
      )
      .then(member => {
        dispatch(addPointOfContact(member));
      });
  };

export const updatePointOfContact =
  (
    member: Member,
    dept: Department,
    newRole?: Role,
  ): AppAction<Promise<void>> =>
  dispatch => {
    return memberApi.members
      .updateMember(
        member.userId,
        dept.id,
        member.type,
        newRole?.id,
        member.startDate,
        member.endDate,
        member.status,
      )
      .then(member => {
        dispatch(
          updatePointOfContactSlice({
            id: member.userId + member.groupId,
            changes: member,
          }),
        );
      });
  };

export const removePointOfContact =
  (member: Member, dept: Department): AppAction<Promise<void>> =>
  dispatch => {
    return memberApi.members
      .deleteMember(member.groupId, member.userId)
      .then(resp => {
        dispatch(deletePointOfContact(member.userId + member.groupId));
      });
  };
