import EmployeePicture from 'app/components/shared/EmployeePicture';
import { useAppSelector } from 'app/store';
import { selectUserById } from 'app/store/users/selectors';
import Member from 'app/types/Member';
import { Card, CardContent, Typography } from 'cfa-react-components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import './SearchPage.scss';

export const PeopleSearchPicture = (props: Member | any) => {
  const user = useAppSelector(selectUserById(props.result.userId));
  const navigate = useNavigate();
  const clickMember = (id: string) => {
    navigate(`/profile/${id}`);
  };
  const checkUserNavigate = (props: any) => {
    if (clickMember) {
      if (props.result.userId) {
        clickMember(props.result.userId);
      } else {
        clickMember(props.result.id!);
      }
    }
  };

  const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 7vh;
    margin-right: 7vh;
    padding-top: 2vh;
  `;

  return (
    <Card
      style={{
        margin: '15px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '280px',
      }}
      onClick={() => checkUserNavigate(props)}
      elevation={0}
    >
      <CardContent>
        <StyledDiv>
          <EmployeePicture user={user!} imgWidth="175px" imgHeight="175px" />
          <Typography
            variant="h4"
            color="secondary"
            fontWeight="medium"
            align="center"
            style={{
              cursor: 'pointer',
            }}
            data-cy="search-results"
          >
            {props.result.name}
          </Typography>
        </StyledDiv>
      </CardContent>
    </Card>
  );
};
