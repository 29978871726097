import Member from 'app/types/Member';
import { Role } from 'app/types/Role';
import { allowAddPointOfContact } from 'app/utils/hasPermissions/allowFeature';
import { List } from 'cfa-react-components';
import { PointOfContactAccordionListItem } from '././PointOfContactAccordionListItem';
import { PointOfContactAccordionProps } from '././PointOfContactAccordionMenu';
import AddPointOfContactListItem from './AddPointOfContactListItem';

export type PointOfContactAccordionListProps = {
  pointOfContactList: Member[];
  roleList: Role[];
} & PointOfContactAccordionProps;

export const PointOfContactAccordionList = ({
  currentDept,
  subDeptMap,
  pointOfContactList,
  roleList,
}: PointOfContactAccordionListProps) => {
  const sortedPointsOfContact = pointOfContactList.sort((a, b) => {
    const roleA = roleList.find(role => role.id === a.roleId)?.name || '';
    const roleB = roleList.find(role => role.id === b.roleId)?.name || '';
    return roleA.localeCompare(roleB);
  });
  const allowAddingPointOfContact: boolean = allowAddPointOfContact();
  return (
    <>
      <List
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 50%)',
        }}
      >
        {sortedPointsOfContact.map(pointOfContact => (
          <PointOfContactAccordionListItem
            key={pointOfContact.userId}
            pointOfContact={pointOfContact}
            roleList={roleList}
            currentDept={subDeptMap.get(currentDept)!}
          />
        ))}
        <AddPointOfContactListItem
          currentDepartment={subDeptMap.get(currentDept)!}
          rolesList={roleList}
          allowAddPointOfContact={allowAddingPointOfContact}
        />
      </List>
    </>
  );
};

export default PointOfContactAccordionList;
