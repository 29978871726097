import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import ApiState from 'app/constants/ApiState';
import ApiStatus from 'app/types/ApiStatus';
import Member from 'app/types/Member';

interface PointsOfContactState {
  // Key is department id
  entities: Member[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const defaultApiStatus: ApiStatus = {
  state: ApiState.IDLE,
  error: null,
};

export const PointsOfContactAdapter = createEntityAdapter<Member>({
  selectId: a => a.userId + a.groupId,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = PointsOfContactAdapter.getInitialState({
  apiStatus: {
    loadPointsOfContact: defaultApiStatus,
    updatePointOfContact: defaultApiStatus,
    addPointOfContact: defaultApiStatus,
    removePointOfContact: defaultApiStatus,
  },
});

const pointsOfContactSlice = createSlice({
  name: 'pointsOfContact',
  initialState,
  reducers: {
    addPointOfContact: PointsOfContactAdapter.addOne,
    updatePointOfContact: PointsOfContactAdapter.updateOne,
    deletePointOfContact: PointsOfContactAdapter.removeOne,
    setPointsOfContactRequest: state => {
      state.apiStatus.loadPointsOfContact.state = ApiState.LOADING;
      state.apiStatus.loadPointsOfContact.error = null;
    },
    setPointsOfContactSuccess: (state, action: PayloadAction<Member[]>) => {
      state.apiStatus.loadPointsOfContact.state = ApiState.IDLE;
      state.apiStatus.loadPointsOfContact.error = null;
      PointsOfContactAdapter.setAll(state, action);
    },
    setPointsOfContactError: (state, action: PayloadAction<string>) => {
      state.apiStatus.loadPointsOfContact.state = ApiState.ERROR;
      state.apiStatus.loadPointsOfContact.error = action.payload;
    },
  },
});

export const {
  addPointOfContact,
  updatePointOfContact,
  deletePointOfContact,
  setPointsOfContactRequest,
  setPointsOfContactSuccess,
  setPointsOfContactError,
} = pointsOfContactSlice.actions;

export default pointsOfContactSlice;
