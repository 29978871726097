import * as React from 'react';

import { Check } from '@cfa-icons/system';
import RoleType from 'app/constants/ApiRole';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch } from 'app/store';
import { createRole } from 'app/store/roles/actions';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Container,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
} from 'cfa-react-components';

export const CreateRole = () => {
  const dispatch = useAppDispatch();
  const [role, setRole] = React.useState('');
  const [open, setOpen] = React.useState(false);

  // When the user clicks on the "Create New" button
  const handleClickOpen = () => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    setOpen(true);
  };
  // The input field for the new role name is cleared when the user closes handle without creating a new role
  const handleClose = () => {
    setOpen(false);
    setRole('');
  };

  const handleSaveClose = () => {
    setOpen(false);
    setRole(role);
    dispatch(createRole(role, RoleType.POD_ROLE))
      .then(() => {
        setOpen(false);
        setRole(role);
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.createRole);
      });
  };
  return (
    <>
      <Button
        className="create_btn"
        size="md"
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        style={{
          marginLeft: '20px',
          marginBottom: '10px',
          fontSize: '17px',
        }}
      >
        <React.Fragment key=".0">
          <Icon icon={Check} data-cy="create-pod-role-btn" />
          Create New
        </React.Fragment>
      </Button>

      <Modal
        data-cy="cancel-role"
        show={open}
        onClose={handleClose}
        size="md"
        scrollMode="modal-body"
      >
        <React.Fragment key=".0">
          <ModalHeader>Create New Role</ModalHeader>
          <ModalBody>
            To create a new role, enter a new role name.
            <Container style={{ textAlign: 'left', padding: 20 }}>
              <React.Fragment key=".0">
                <TextField
                  label="Role"
                  required
                  onChange={e => setRole(e.target.value)}
                  defaultValue=""
                  placeholder="Enter new role name"
                  fullWidth
                  data-cy="pod-role-in"
                />
              </React.Fragment>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleSaveClose}
              variant="filled"
              disabled={!role}
              data-cy="post-pod-role-btn"
            >
              Create Role
            </Button>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    </>
  );
};
export default CreateRole;
