import { IconDots } from '@tabler/icons-react';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import { removeDepartment, updateDepartment } from 'app/store/dept/actions';
import { selectDepartmentById } from 'app/store/dept/selectors';
import Department from 'app/types/Department';
import { adminAccess } from 'app/utils/hasPermissions/allowFeature';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Col,
  Container,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
  Tooltip,
} from 'cfa-react-components';
import React, { useState } from 'react';
import { DeleteObjectModal } from './DeleteObjectModal';

export const EditDept = ({
  subDeptName,
  ...args
}: {
  subDeptName: string;
  subdeptID: string;
  dept: Department;
}) => {
  const allowDelete: boolean = adminAccess();
  const dispatch = useAppDispatch();
  const dept = useAppSelector(selectDepartmentById(args.dept.id)) as Department;
  const [open, setOpen] = React.useState(false);
  const [updatedDeptName, setUpdatedDeptName] = useState<string>(dept.name);
  const [updatedDeptAlias, setUpdatedDeptAlias] = useState<string>(dept.alias);

  const handleClickOpen = () => {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUpdatedDeptAlias(dept.alias);
    setUpdatedDeptName(dept.name);
  };

  const handleSaveClose = () => {
    dispatch(updateDepartment(dept.id, updatedDeptName, updatedDeptAlias))
      .then(() => {
        setOpen(false);
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.editDepartment, dept.id);
      });
  };

  const handleDelete = () => {
    dispatch(removeDepartment(dept.id))
      .then(() => {
        setOpen(false);
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.deleteDepartment, dept.id);
        return;
      });
  };

  return (
    <div>
      <IconButton
        size="sm"
        color="secondary"
        onClick={handleClickOpen}
        data-cy="edit-dept-btn"
      >
        <Tooltip content="Edit">
          <Icon className="edit_icon" icon={IconDots}></Icon>
        </Tooltip>
      </IconButton>
      <Modal
        show={open}
        onClose={handleClose}
        size="md"
        scrollMode="modal-body"
      >
        <React.Fragment key=".0">
          <ModalHeader>Edit Sub-department</ModalHeader>
          <ModalBody>
            To edit the selected Sub-department, enter a new role name. To
            delete the selected Sub-department entirely, click “Delete
            Sub-department.
            <Container style={{ textAlign: 'left', padding: 20 }}>
              <Col style={{ marginTop: 10 }}>
                <TextField
                  label="Department Name"
                  required
                  onChange={e => setUpdatedDeptName(e.target.value)}
                  placeholder="Enter new Department name"
                  fullWidth
                  value={updatedDeptName}
                />
              </Col>
              <Col style={{ marginTop: 10 }}>
                <TextField
                  label="Department Alias"
                  helperText="Example: DTT for Digital Transformation & Technology"
                  required
                  value={updatedDeptAlias}
                  onChange={e => setUpdatedDeptAlias(e.target.value)}
                  placeholder="Enter department alias"
                  data-cy="edit-dept-in"
                />
              </Col>
            </Container>
          </ModalBody>
          <ModalFooter>
            <DeleteObjectModal
              itemName={dept.name}
              handleDelete={handleDelete}
              handleClose={handleClose}
              type="Sub-department"
              allowDelete={allowDelete}
            />
            <Button
              onClick={handleSaveClose}
              variant="filled"
              data-cy="put-dept-btn"
            >
              Edit Sub-department
            </Button>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    </div>
  );
};
export default EditDept;
