import { useState } from 'react';

import {
  Divider,
  Icon,
  IconButton,
  Logo,
  PopoverMenu,
  PopoverMenuContent,
  PopoverMenuItem,
  PopoverMenuToggleButton,
} from 'cfa-react-components';
import { NavLink, useNavigate } from 'react-router-dom';
import '../../../App.scss';
import { MenuItems } from './MenuItems';
import './Navigation.scss';

import { Logout, User } from '@cfa-icons/system';
import { useOktaAuth } from '@okta/okta-react';
import SearchBar2 from 'app/pages/Search/SearchBar2';
import { getPersonalId } from 'app/utils/getPersonalId';
import { adminAccess } from 'app/utils/hasPermissions/allowFeature';

export const Navigation = () => {
  const [hasAdminAccess, setHasAdminAccess] = useState(adminAccess());
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();

  const logout = async () => {
    oktaAuth.signOut();
  };

  const onProfileClick = () => {
    const personalID = getPersonalId();
    navigate(`/profile/${personalID}`);
  };

  return (
    <>
      <nav className="NavbarItems">
        <div className="start-section">
          <NavLink to="/home">
            {' '}
            <Logo color="primary" logo="script" size="sm" />{' '}
          </NavLink>
          {/* This below ul holds a method in JS - <array>.map. This takes the contents of an array
              and allows us to map the results to an element which in this case, li. */}
          <ul className="nav-menu" data-cy="nav-bar">
            {MenuItems.map((item, index) => {
              if (item.title === 'Admin' && !hasAdminAccess) {
                return null;
              }
              return (
                <li id="nav-next" key={index}>
                  <NavLink to={item.url} className={item.cName}>
                    {item.title}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="search_account">
          <SearchBar2 />
        </div>
        {/* This is the user icon button. */}
        <PopoverMenu maxHeight="216px" placement="bottom-start">
          <PopoverMenuToggleButton as={IconButton}>
            <Icon icon={User} size="md" color="#5B6770"></Icon>
          </PopoverMenuToggleButton>
          <PopoverMenuContent>
            <PopoverMenuItem onClick={onProfileClick}>
              <Icon icon={User} />
              Profile
            </PopoverMenuItem>
            <Divider></Divider>
            <PopoverMenuItem onClick={logout}>
              <Icon icon={Logout} />
              Logout
            </PopoverMenuItem>
          </PopoverMenuContent>
        </PopoverMenu>
      </nav>
    </>
  );
};

export default Navigation;
