import AppConstants from 'app/utils/AppConstants';
import createApiClient from './createApiClient';

const apiClient = createApiClient(AppConstants.URL.PICTURE);

const pictureApi = {
  get_image_by_id: async (employeeNumber: string) => {
    const response = await apiClient.get(`${employeeNumber}`);
    return response;
  },
};

export default pictureApi;
