import { usePointOfContactRoles } from 'app/store/roles/selectors';
import Role from 'app/types/Role';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LineBreak,
  Results,
  SearchHeader,
  SearchSectionProps,
} from './SearchPage';

export const PointOfContactRoleSearchSection = ({
  search,
}: SearchSectionProps) => {
  const navigate = useNavigate();

  const roles = usePointOfContactRoles();
  const [roleResults, setRoleResults] = useState<Role[]>([] as Role[]);
  const [roleCollapse, setRoleCollapse] = useState(true);

  useEffect(() => {
    const filteredRoles = roles.filter(role =>
      role.name.toLowerCase().includes(search.toLowerCase()),
    );
    setRoleResults(filteredRoles);
  }, [search]);

  return (
    <>
      <SearchHeader
        title="Point Of Contact Roles"
        size={roleResults.length}
        collapse={roleCollapse}
        setCollapse={setRoleCollapse}
      />
      <LineBreak />
      <Results<Role> results={roleResults} collapse={roleCollapse} />
    </>
  );
};
