import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import MemberInfoSection from 'app/components/shared/CardRoleGroups/MemberInfoSection';
import { PictureDownloadButton } from 'app/components/shared/PictureDownloadButton/PictureDownloadButton';
import { useAppSelector } from 'app/store';
import { selectSelectedDeptTab } from 'app/store/app/selectors';
import { selectDepartments } from 'app/store/dept/selectors';
import {
  selectPodById,
  selectPodMembersByPodId,
} from 'app/store/pods/selectors';
import { usePodRoles } from 'app/store/roles/selectors';
import { EmployeeImageData } from 'app/types/ImageTypes';
import {
  allowCreateEditPod,
  allowDeletePod,
} from 'app/utils/hasPermissions/allowFeature';
import {
  Breadcrumbs,
  Button,
  Divider,
  List,
  Switch,
  Tooltip,
  Typography,
} from 'cfa-react-components';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound';
import './PodStyling.scss';

export const PodsProfilePage = () => {
  const navigate = useNavigate();
  const { podId } = useParams() as { podId: string };

  const pod = useAppSelector(selectPodById(podId));
  const allowPodCreateEdit: boolean = allowCreateEditPod();
  const allowPodDelete: boolean = allowDeletePod();
  const rolesList = usePodRoles();
  const podMembers = useAppSelector(selectPodMembersByPodId(podId));
  const [staffOnly, setStaffOnly] = useState(false);
  const [userImageData, setUserImageData] = useState<EmployeeImageData[]>([]);
  const departments = useAppSelector(selectDepartments);
  const selectedDeptTab = useAppSelector(selectSelectedDeptTab);
  let dept = departments.find(dept => dept.id === pod?.deptId) ?? undefined;
  const EditPods = () => {
    navigate(`/pods/${pod?.id}/edit`);
  };

  return pod ? (
    <>
      <Navigation />
      <div className="main-container">
        <div className="top-section">
          <Breadcrumbs
            breadcrumbs={[
              {
                label: 'Pods',
                onClick: function backToPods() {
                  navigate('/pods');
                },
              },
              {
                label: pod?.name,
              },
            ]}
          />
          <div></div>
          {allowPodCreateEdit ? (
            <Button
              color="primary"
              onClick={EditPods}
              size="md"
              variant="outlined"
              style={{
                justifySelf: 'end',
              }}
              disabled={!allowPodCreateEdit}
              data-cy="pod-edit-btn-allowed"
            >
              Edit Pod
            </Button>
          ) : (
            <Tooltip content="Insufficient Permissions">
              <span>
                <Button
                  color="primary"
                  onClick={function noRefCheck() {}}
                  size="md"
                  variant="outlined"
                  style={{
                    justifySelf: 'end',
                  }}
                  disabled={!allowPodCreateEdit}
                  data-cy="pod-edit-btn-disallowed"
                >
                  Edit Pod
                </Button>
              </span>
            </Tooltip>
          )}
        </div>
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
            data-cy="pod-profile-name"
          >
            {pod.name}
            <div style={{ color: '#80878C', fontSize: '22px' }}>
              {dept?.name}
            </div>
          </Typography>
        </div>
        <div>
          <Typography
            variant="body1"
            color="secondary"
            fontWeight="medium"
            align="center"
            gutterBottom
            style={{
              marginTop: '40px',
              marginLeft: '85px',
              marginRight: '85px',
              margin: '0 auto',
            }}
          >
            {pod.info}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            paddingTop: '30px',
            paddingLeft: '50%',
          }}
        >
          <Switch
            data-cy="toggle"
            aria-label="Filter staff?"
            label="Staff Only"
            crossOrigin={undefined}
            checked={staffOnly}
            onChange={e => {
              setStaffOnly(e.currentTarget.checked);
            }}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            style={{
              // alignSelf: "flex-end",
              marginTop: '-22px',
              marginBottom: '0px',
              paddingLeft: '275px',
            }}
          />
        </div>
        <div
          style={{
            width: '800px',
            margin: '0 auto',
            marginTop: '20px',
          }}
        >
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px' }}
          ></Divider>
          <div id="imageDownload">
            <List
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gridTemplateColumns: 'repeat(4, 1fr)',
                justifyContent: 'center',
                alignItems: 'stretch',
              }}
            >
              {podMembers
                ?.filter(member => {
                  return !staffOnly || !member.isContingent;
                })
                .map((member, index) => (
                  <div
                    key={member.userId}
                    style={{
                      flexBasis: '20%',
                      minWidth: '150px',
                    }}
                  >
                    <MemberInfoSection
                      member={member}
                      index={index}
                      rolesList={rolesList}
                      setUserImageData={setUserImageData}
                    />
                  </div>
                ))}
            </List>
          </div>
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px' }}
          ></Divider>
          <PictureDownloadButton
            userImageData={userImageData}
            staffOnly={staffOnly}
            name={pod.name}
          />
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <PageNotFound></PageNotFound>
  );
};
