import { Download } from '@cfa-icons/system';
import { useAppSelector } from 'app/store';
import { selectLoadUserStatus } from 'app/store/users/selectors';
import { EmployeeImageData } from 'app/types/ImageTypes';
import { createDownloads } from 'app/utils/download';
import { Icon, Typography } from 'cfa-react-components';
import './PictureDownloadButton.scss';

interface PictureDownloadButtonProps {
  userImageData: EmployeeImageData[];
  staffOnly: boolean;
  name: string;
}

export const PictureDownloadButton = (params: PictureDownloadButtonProps) => {
  const status = useAppSelector(selectLoadUserStatus);
  // const [hovered, setHovered] = useState(false);

  return (
    <div className="download-area">
      <Typography
        className="buttonText"
        variant="body1"
        color="secondary"
        fontWeight="medium"
        align="center"
        disabled={status.state !== 'idle'} // Conditionally set disabled based on isDisabled variable
        gutterBottom
        onClick={
          status.state == 'idle'
            ? () =>
                createDownloads(
                  params.userImageData,
                  params.staffOnly,
                  params.name,
                )
            : () => {}
        }
      >
        Download
        <Icon
          className={status.state == 'idle' ? 'icon-enabled' : 'icon-disabled'}
          icon={Download}
        ></Icon>
      </Typography>
    </div>
  );
};
