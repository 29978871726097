import { useAppSelector } from 'app/store';
import { selectLeadership } from 'app/store/leadership/selectors';
import { selectPods } from 'app/store/pods/selectors';
import Member from 'app/types/Member';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LineBreak,
  Results,
  SearchHeader,
  SearchSectionProps,
} from './SearchPage';

export const PeopleSearchSection = ({ search }: SearchSectionProps) => {
  const navigate = useNavigate();
  const [allMembers, setAllMembers] = useState<Member[]>([] as Member[]);
  const pods = useAppSelector(selectPods);
  const teams = useAppSelector(selectLeadership);

  const clickMember = (id: string) => {
    navigate(`/profile/${id}`);
  };

  const getAllMembers = () => {
    pods.forEach(pod => {
      if (pod.members !== undefined) {
        setAllMembers(allMembers => [...allMembers, ...pod.members!]);
      }
    });

    teams.forEach(team => {
      if (team.members !== undefined) {
        setAllMembers(allMembers => [...allMembers, ...team.members!]);
      }
    });
  };

  const [memberResults, setMemberResults] = useState<Member[]>([] as Member[]);
  const [memberCollapse, setMemberCollapse] = useState(true);

  const getUniqueItems = (items: Member[]) => {
    return items.reduce((acc, currentItem) => {
      if (currentItem.name.toLowerCase().includes(search.toLowerCase())) {
        const isDuplicate = acc.some(
          existingItem => existingItem.userId === currentItem.userId,
        );
        if (!isDuplicate) {
          acc.push(currentItem);
        }
      }
      return acc;
    }, [] as Member[]);
  };

  useEffect(() => {
    getAllMembers();
    const filtered = getUniqueItems(allMembers);
    setMemberResults(filtered);
  }, [search]);

  return (
    <>
      <SearchHeader
        title="People"
        size={memberResults.length}
        collapse={memberCollapse}
        setCollapse={setMemberCollapse}
      />
      <LineBreak />
      <Results<Member>
        results={memberResults}
        collapse={memberCollapse}
        navigation={clickMember}
        showPicture={true}
      />
    </>
  );
};
