import identityApi from 'app/services/IdentityApi';
import Department from 'app/types/Department';
import IdentityUser from 'app/types/IdentityUser';
import Member from 'app/types/Member';
import Role from 'app/types/Role';
import { Container, Divider, ListItem, Typography } from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EmployeePicture from '../EmployeePicture';
import EditPointOfContact from '../FormDialog/EditPointOfContact';
import './PointOfContactAccordionListItem.scss';

export type PointOfContactAccordionListItemProps = {
  pointOfContact: Member;
  roleList: Role[];
  currentDept: Department;
};

export const PointOfContactAccordionListItem = ({
  pointOfContact,
  roleList,
  currentDept,
}: PointOfContactAccordionListItemProps) => {
  const [pointOfContactRoleName, setPointOfContactRoleName] =
    useState<string>('');
  const [user, setUser] = useState<IdentityUser>();
  const navigate = useNavigate();

  const clickPointOfContact = () => {
    navigate(`/profile/${pointOfContact.userId}`);
  };

  useEffect(() => {
    identityApi
      .get_user_by_id(
        pointOfContact.userId,
        pointOfContact.isContingent ? 'contractors' : 'staff',
      )
      .then(data => setUser(data))
      .catch(e => console.log('Error occurred while fetching user:', e));

    let role =
      roleList.find(role => role.id === pointOfContact.roleId) ?? undefined;
    role
      ? setPointOfContactRoleName(role.name)
      : setPointOfContactRoleName('PointOfContact');
  }, [roleList]);

  return (
    <>
      <ListItem
        startItem={
          <Container style={{ marginTop: '7px' }}>
            <EmployeePicture user={user!} imgHeight="30px" imgWidth="30px" />
          </Container>
        }
        endItem={
          <EditPointOfContact
            currentDepartment={currentDept}
            rolesList={roleList}
            point_of_contact={pointOfContact}
          />
        }
        style={{ width: '100%' }}
      >
        <Typography
          className="points-of-contact"
          variant="body1"
          color="secondary"
          fontWeight="bold"
          align="left"
          style={{ paddingTop: '10px', paddingLeft: '10px' }}
          data-cy={`points-of-contact-person-${pointOfContact.name}`}
          onClick={clickPointOfContact}
        >
          {pointOfContactRoleName}: {pointOfContact.name}
        </Typography>
        <Divider
          className="divider"
          variant="fullLength"
          style={{ paddingTop: '10px' }}
        />
      </ListItem>
    </>
  );
};

export default PointOfContactAccordionListItem;
